body {
  background-color: #fefeff;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}

.backer-table {    
  background-color:white;
}

.bolder-headings {
  font-weight: bolder;
}

.bottom-border {
  border-bottom:1px solid black
}

.button-column-width {
  width:120px;
}

.checkbox-width {
  width:35px;
}

.inline-block {
  display:inline-block;
  padding-left:5px;
}

.large-emphasis {
  margin-top:15px;
  font-size:1.25em;
  font-weight: bolder;
}

.left-justified {
  text-align: left;
}
.listwrapper {
  padding-top:20px;
}
.mastercategory {
  clear:both;
  padding-top:15px;
  padding-bottom:10px;
  width:100%;
}

.max-height {
  height:400px;
  overflow: auto;
}

.short-input {
  margin-right:10px;
  width:120px;
}

.max-width {
  max-width: 400px;
}

.middle {
  vertical-align: middle;
}

.td-table-cell {
  padding:0px !important;
  border-bottom:2px solid #afafaf;
  margin-left:auto;
  margin-right:auto;
}

.subtable {
  margin:0px!important;
  margin-left:10px;
  border:1px solid darkgray;
  font-size: 0.8em;
  width:100%;
}

.under-emphasis {
  font-style: italic;
  font-size:0.85em;
}

.extra-large-emphasis {
  margin-top:15px;
  font-size:1.55em;
  font-weight: bolder;
}

.medium-emphasis {
  font-style: italic;
  font-size:.85em;
  font-weight:bolder;
  color:#f54545;
}

.soft-blue {
  color: #6699ff
}

.extended-padding {
  margin-top:10px;
  margin-bottom:20px;
}

.tools-cs {
  color: darkgreen;
}

.tools-cs:hover {
  color: green;
}
h1 {
  font-size:1.8em;
  padding-top:10px;
  padding-bottom:15px;
}

h2 {
  font-size:1.6em;
  padding-top:10px;
  padding-bottom:5px;
}

h3 {
  font-size:1.4em;
  padding-top:10px;
  padding-bottom:2px;
}

h4 {
  font-size:1.2em;
  padding-top:10px;
  padding-left:15px;
  padding-bottom:2px;
}

h5 {
  font-size:1em;
  padding-top:5px;
  padding-left:15px;
  text-decoration: underline;
}

.row {
  width:100%
}

.group {
  border:1px solid blue;
}

.no-bottom-margin {
  margin-bottom:0px;
}

.no-top-margin {
  margin-top:0px;
}

footer {
  margin-top:25px;
  text-align: center;
}

.fakelink {
  border:0px;
  color:blue;
}

.fakelink2 {
  border:0px;
  color:darkblue;
}


.fakelink:hover {
  border:0px;
  color:lightblue;
  cursor:hand;
}

.fakelink2:hover {
  border:0px;
  color:blue;
}

a.header-link {
  color:#121212;
  text-decoration: none;
}

.sub-topic {
  color:red;
}

.ident1 {
  padding-left:5px;
}

.ident2 {
  padding-left:15px;
}

.ident3 {
  padding-left:25px;
}

.ident4 {
  padding-left:20px;
}

.ident5 {

}
.skillContainer {
   
    background-color:#eeeeee;
    border-right:1px solid #dddddd;
    border-bottom:1px solid #dddddd;
    margin:5px;
    padding:2px;
}

.padding-0{
    padding-right:0;
    padding-left:0;
}

.padding-right-0{
    padding-right:0;
}


.margin-left-5 {
    margin-left:5px;
}

.techniquebox {
    font-size:1.1em;
    font-weight:bold;
}

.skillbox {
    margin:1px;
    padding:1px;
    padding-left:2px;
    padding-right:2px;
    display:inline-block;
    background-color:#ccdffc;
    border-right:1px solid #96bdf7;
    border-bottom:1px solid #96bdf7;
}

.realmbox {
    margin:1px;
    padding:1px;
    padding-left:2px;
    padding-right:2px;
    display:inline-block;
    background-color:#fae9c0;
    border-right:1px solid #fada8f;
    border-bottom:1px solid #fada8f
}

.focusbox {
    margin:1px;
    padding:1px;
    padding-left:2px;
    padding-right:2px;
    display:inline-block;
    background-color:#fcd6bf;
    border-right:1px solid#f7b78f;
    border-bottom:1px solidd#f7b78f;
}



.center-middle {
    padding-top:3px;
    text-align: center;
    vertical-align: middle;
}

.character-score {
    float: right;
}

.emphasis {
    font-size: 1.1em;
    font-weight: bold;
}

.height {
    vertical-align: bottom;
}

.less-bottom {
    margin-bottom:3px;
}

.mild-emphasis {
    font-weight:100;
    font-style: italic;
    color:#999999;
}

.no-bottom {
    margin-bottom:0px;
}

.score {
    display: inline-block;
    font-size: 1.1em;
    font-weight: bold;
    width:60px;
    text-align:right;
}

.search {
    width:100%;
}

.skills-grid {
    padding:1px;
    padding-left:0px;
    border: 1px solid grey;
    background-color:#3f3f3f;
}

.small-blue-text {
    font-size:0.8em;
    color: blue
}

.small-green-text {
    font-size:0.8em;
    color: darkgreen
}

.small-warning-text {
    margin-left:40px;
    font-size:0.8em;
    color:red;
}
.text-section-body {
    width:90%;
    height:150px;
}
.top-margin {
    margin-top:10px;
}


.skill-button {
    width:100%;
    margin-left:2px;
    margin-right:2px;
    text-align:center;
    font-weight:bold;
    color:white;
    background-color: green;
    border:1px solid lightgray;
}

.skills-grid {
    padding:0;
    border: 1px solid grey;
    background-color:#3f3f3f;
}
.border {
    border:1px solid red;
}

.currency {
    width:50%;
    display:inline-block;
}

.encumb {
    padding-top:5px;
}

.fullwidth {
    margin:5px;
    clear:both;
    width:100%;
    display:block;
}

.inline-block {
    display:inline-block;
}

.inventory-item {
    width:225px;
    border-top:1px solid darkgray;
    border-left:1px solid darkgray;
    border-right:2px solid darkgray;
    border-bottom:2px solid darkgray;
    margin:3px;
    padding:3px;
    float:left;
}

.inventory-item:hover {
}

.inventory-item-name {
    width:250px;
    display:inline-block;

}

.inventory-item-price {
    width:200px;
    display:inline-block;
}

.inventory-item-stats {
    display:inline-block;
}

.select-width {
    width: 160px;
}

.margin {
    margin:5px;
}

.nothing {
    margin-left: 10px;
    color: gray;
    font-style: italic;
}

.short-input {
    width: 80px;
}

.wide {
    width:400px;
}

.width {
    width:80px;
}

.soft-blue {
    color: #6699ff
}

.under-emphasis {
    font-style: italic;
    font-size:0.85em;
}
.button-style {
    margin:5px;
}

.description {
    width:100%;
    height:150px;
}

.soft-blue {
    color: #6699ff
}
