.border {
    border:1px solid red;
}

.currency {
    width:50%;
    display:inline-block;
}

.encumb {
    padding-top:5px;
}

.fullwidth {
    margin:5px;
    clear:both;
    width:100%;
    display:block;
}

.inline-block {
    display:inline-block;
}

.inventory-item {
    width:225px;
    border-top:1px solid darkgray;
    border-left:1px solid darkgray;
    border-right:2px solid darkgray;
    border-bottom:2px solid darkgray;
    margin:3px;
    padding:3px;
    float:left;
}

.inventory-item:hover {
}

.inventory-item-name {
    width:250px;
    display:inline-block;

}

.inventory-item-price {
    width:200px;
    display:inline-block;
}

.inventory-item-stats {
    display:inline-block;
}

.select-width {
    width: 160px;
}

.margin {
    margin:5px;
}

.nothing {
    margin-left: 10px;
    color: gray;
    font-style: italic;
}

.short-input {
    width: 80px;
}

.wide {
    width:400px;
}

.width {
    width:80px;
}

.soft-blue {
    color: #6699ff
}

.under-emphasis {
    font-style: italic;
    font-size:0.85em;
}