h1 {
  font-size:1.8em;
  padding-top:10px;
  padding-bottom:15px;
}

h2 {
  font-size:1.6em;
  padding-top:10px;
  padding-bottom:5px;
}

h3 {
  font-size:1.4em;
  padding-top:10px;
  padding-bottom:2px;
}

h4 {
  font-size:1.2em;
  padding-top:10px;
  padding-left:15px;
  padding-bottom:2px;
}

h5 {
  font-size:1em;
  padding-top:5px;
  padding-left:15px;
  text-decoration: underline;
}

.row {
  width:100%
}

.group {
  border:1px solid blue;
}

.no-bottom-margin {
  margin-bottom:0px;
}

.no-top-margin {
  margin-top:0px;
}

footer {
  margin-top:25px;
  text-align: center;
}

.fakelink {
  border:0px;
  color:blue;
}

.fakelink2 {
  border:0px;
  color:darkblue;
}


.fakelink:hover {
  border:0px;
  color:lightblue;
  cursor:hand;
}

.fakelink2:hover {
  border:0px;
  color:blue;
}

a.header-link {
  color:#121212;
  text-decoration: none;
}

.sub-topic {
  color:red;
}

.ident1 {
  padding-left:5px;
}

.ident2 {
  padding-left:15px;
}

.ident3 {
  padding-left:25px;
}

.ident4 {
  padding-left:20px;
}

.ident5 {

}