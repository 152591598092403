.button-style {
    margin:5px;
}

.description {
    width:100%;
    height:150px;
}

.soft-blue {
    color: #6699ff
}