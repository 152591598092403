.skillContainer {
   
    background-color:#eeeeee;
    border-right:1px solid #dddddd;
    border-bottom:1px solid #dddddd;
    margin:5px;
    padding:2px;
}

.padding-0{
    padding-right:0;
    padding-left:0;
}

.padding-right-0{
    padding-right:0;
}


.margin-left-5 {
    margin-left:5px;
}

.techniquebox {
    font-size:1.1em;
    font-weight:bold;
}

.skillbox {
    margin:1px;
    padding:1px;
    padding-left:2px;
    padding-right:2px;
    display:inline-block;
    background-color:#ccdffc;
    border-right:1px solid #96bdf7;
    border-bottom:1px solid #96bdf7;
}

.realmbox {
    margin:1px;
    padding:1px;
    padding-left:2px;
    padding-right:2px;
    display:inline-block;
    background-color:#fae9c0;
    border-right:1px solid #fada8f;
    border-bottom:1px solid #fada8f
}

.focusbox {
    margin:1px;
    padding:1px;
    padding-left:2px;
    padding-right:2px;
    display:inline-block;
    background-color:#fcd6bf;
    border-right:1px solid#f7b78f;
    border-bottom:1px solidd#f7b78f;
}



.center-middle {
    padding-top:3px;
    text-align: center;
    vertical-align: middle;
}

.character-score {
    float: right;
}

.emphasis {
    font-size: 1.1em;
    font-weight: bold;
}

.height {
    vertical-align: bottom;
}

.less-bottom {
    margin-bottom:3px;
}

.mild-emphasis {
    font-weight:100;
    font-style: italic;
    color:#999999;
}

.no-bottom {
    margin-bottom:0px;
}

.score {
    display: inline-block;
    font-size: 1.1em;
    font-weight: bold;
    width:60px;
    text-align:right;
}

.search {
    width:100%;
}

.skills-grid {
    padding:1px;
    padding-left:0px;
    border: 1px solid grey;
    background-color:#3f3f3f;
}

.small-blue-text {
    font-size:0.8em;
    color: blue
}

.small-green-text {
    font-size:0.8em;
    color: darkgreen
}

.small-warning-text {
    margin-left:40px;
    font-size:0.8em;
    color:red;
}
.text-section-body {
    width:90%;
    height:150px;
}
.top-margin {
    margin-top:10px;
}


.skill-button {
    width:100%;
    margin-left:2px;
    margin-right:2px;
    text-align:center;
    font-weight:bold;
    color:white;
    background-color: green;
    border:1px solid lightgray;
}

.skills-grid {
    padding:0;
    border: 1px solid grey;
    background-color:#3f3f3f;
}