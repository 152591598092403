body {
  background-color: #fefeff;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}

.backer-table {    
  background-color:white;
}

.bolder-headings {
  font-weight: bolder;
}

.bottom-border {
  border-bottom:1px solid black
}

.button-column-width {
  width:120px;
}

.checkbox-width {
  width:35px;
}

.inline-block {
  display:inline-block;
  padding-left:5px;
}

.large-emphasis {
  margin-top:15px;
  font-size:1.25em;
  font-weight: bolder;
}

.left-justified {
  text-align: left;
}
.listwrapper {
  padding-top:20px;
}
.mastercategory {
  clear:both;
  padding-top:15px;
  padding-bottom:10px;
  width:100%;
}

.max-height {
  height:400px;
  overflow: auto;
}

.short-input {
  margin-right:10px;
  width:120px;
}

.max-width {
  max-width: 400px;
}

.middle {
  vertical-align: middle;
}

.td-table-cell {
  padding:0px !important;
  border-bottom:2px solid #afafaf;
  margin-left:auto;
  margin-right:auto;
}

.subtable {
  margin:0px!important;
  margin-left:10px;
  border:1px solid darkgray;
  font-size: 0.8em;
  width:100%;
}

.under-emphasis {
  font-style: italic;
  font-size:0.85em;
}

.extra-large-emphasis {
  margin-top:15px;
  font-size:1.55em;
  font-weight: bolder;
}

.medium-emphasis {
  font-style: italic;
  font-size:.85em;
  font-weight:bolder;
  color:#f54545;
}

.soft-blue {
  color: #6699ff
}

.extended-padding {
  margin-top:10px;
  margin-bottom:20px;
}

.tools-cs {
  color: darkgreen;
}

.tools-cs:hover {
  color: green;
}